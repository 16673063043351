import { Button, Link, Text, useToast } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';

import React from 'react';
import { useAppState } from './context';

const ADD_TO_CART = gql`
	mutation ATC(
		$input: AddToCartInput = { productId: 3563, variationId: 97, quantity: 1 }
	) {
		addToCart(input: $input) {
			cart {
				subtotal
				total
				shippingTotal
				contents {
					itemCount
					nodes {
						subtotal
						total
						product {
							node {
								name
								sku
								databaseId
								... on VariableProduct {
									price
									regularPrice
								}
							}
						}
					}
				}
			}
		}
	}
`;

const AddToCart: React.FC<{
	productId: number;
	variationId?: number;
	quantity?: number;
	name: String;
	price: String;
}> = ({ productId, variationId, quantity, name, price }) => {
	const toast = useToast();
	const { setCart } = useAppState();
	const [addToCart, { data, loading, error }] = useMutation(ADD_TO_CART, {
		onCompleted: ({ addToCart }) => {
			toast({
				title: (
					<Text>
						Added to&nbsp;
						<Link
							href='/cart'
							fontWeight={700}
							textDecoration='underline'>
							cart
						</Link>
					</Text>
				),
				status: 'success',
			});
			setCart(addToCart.cart);

			// @ts-ignore
			window.dataLayer = window.dataLayer || [];
			// @ts-ignore
			window.dataLayer.push({
				event: 'add_to_cart',
				ecommerce: {
					items: [
						{
							item_name: name,
							item_id: productId,
							price: price ? parseFloat(price.replace('$', '')) : undefined,
							quantity: quantity,
						},
					],
				},
			});
		},
		onError: () => {
			toast({
				title: 'Error',
				description: 'The quantity of this product in your cart exceeds the available stock. Please adjust it to proceed.',
				status: 'error',
			});
		},
	});

	function handleAddToCart() {
		addToCart({
			variables: { input: { productId, variationId, quantity } },
		});
	}

	return (
		<Button
			onClick={() => handleAddToCart()}
			isLoading={loading}
			loadingText='Adding to cart'
			variant='solidBlack'
			bg='#000'
			color='#fff'
			border='1px solid black'
			textTransform='uppercase'
			height='40px'
			fontSize='12px'
			fontWeight='700'
			borderRadius='40px'
			gap='8px'
			colorScheme='#000'
			_hover={{
				color: '#000',
				bg: '#fff',
				border: '1px solid #120B0C',
			}}
			width={{ base: '100%', sm: '208px' }}>
			ADD TO CART
		</Button>
	);
};

export default AddToCart;
